import React, { useCallback, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Copyright from "../components/Footer";
import AppContext from "../contexts/app";
import { updateDBPost } from "../api";

export default function SignInLayout() {
  const { dispatch } = useContext(AppContext);

  const handleServerAuthResponse = (res: any, rememberUser?: boolean) => {
    const { status, message, data } = res;
    if (status === "Error") {
      dispatch({
        type: "OPEN_TOAST_ALERT",
        toastType: "error",
        message,
      });
      localStorage.removeItem(process.env.REACT_APP_TOKEN_TAG);
      localStorage.removeItem(process.env.REACT_APP_REMEMBER_ME_TAG);
    }
    if (status === "Success") {
      const { token, ...restData } = data;
      if (rememberUser)
        localStorage.setItem(process.env.REACT_APP_REMEMBER_ME_TAG, "true");
      localStorage.setItem(process.env.REACT_APP_TOKEN_TAG, token);
      dispatch({
        type: "OPEN_TOAST_ALERT",
        toastType: "success",
        message,
      });
      dispatch({ type: "OPEN_LEFT_SLIDER" });
      dispatch({
        type: "UPDATE_USER",
        payload: {
          isAuthenticated: true,
          ...restData,
        },
      });
      dispatch({ type: "CHANGE_PAGE", page: "Dashboard" });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const rememberUser = !!data.get("remember");
    const email = data.get("email").toString();
    const password = data.get("password").toString();

    updateDBPost("auth/signin", { email, password }).then((res) => {
      handleServerAuthResponse(res, rememberUser);
    });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box></Box>
      <Paper
        elevation={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "#fff !important",
          p: 4,
        }}
      >
        <img
          alt={process.env.REACT_APP_NAME + " Logo"}
          width="auto"
          height="35px"
          src={process.env.REACT_APP_LOGO}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
          Welcome 👋🏻
        </Typography>
        <Typography variant="caption" color="secondary.dark">
          Please sign-in to your account
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            name="remember"
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            LOGIN
          </Button>
          <Grid container>
            <Grid textAlign="center">
              <Typography variant="caption" color={"secondary.dark"}>
                {`New on ${process.env.REACT_APP_NAME}'s Portal?`}
              </Typography>
              <Link
                sx={{ cursor: "pointer", ml: 1 }}
                variant="body2"
                onClick={() =>
                  dispatch({ type: "CHANGE_PAGE", page: "Signup" })
                }
              >
                Create an account
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Copyright />
    </Container>
  );
}
