import axios from "axios";
import { PathPayloadMap } from "../Types/main";

export const updateDBPost = async <Path extends keyof PathPayloadMap>(
  path: Path,
  payload: PathPayloadMap[Path],
) => {
  const res = await axios.post(
    process.env.REACT_APP_BASE_URL,
    {
      payload,
      path,
      token: localStorage?.getItem(process.env.REACT_APP_TOKEN_TAG)?.toString(),
    },
    {
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );
  console.log({ res });
  if (res.status >= 200 && res.status < 400) {
    return res.data;
  }

  throw res.data.error;
};

export const updateDbGet = async (queryParams) => {
  const res = await axios.get(process.env.REACT_APP_BASE_URL, {
    params: queryParams,
  });
  console.log({ res });
  if (res.status >= 200 && res.status < 400) {
    return res.data;
  }

  throw res.data.error;
};
