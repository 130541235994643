export function isValidEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isValidPhone(phone: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(phone);
}

export function getInitials(name: string): string | null {
  if (!name) return null;
  const [fn, ln] = name.split(" ");
  return (fn ? fn[0].toUpperCase() : "") + (ln ? ln[0].toUpperCase() : "");
}

export function addDaysToDate(days: number, date?: Date) {
  return new Date(
    (date ? new Date(date) : new Date()).getTime() + days * 24 * 60 * 60 * 1000,
  );
}
export const splitTimestamp = (timeStamp: Date, addOffset?: boolean) => {
  try {
    let newTimeStamp = timeStamp;
    const day = newTimeStamp.toLocaleString("en-us", { weekday: "long" });
    const offset = newTimeStamp.getTimezoneOffset();
    newTimeStamp = new Date(
      newTimeStamp.getTime() + (addOffset ? +offset : -offset) * 60 * 1000,
    );
    const isoTimestamp = newTimeStamp.toISOString();
    const [date, time] = isoTimestamp.split("T");
    return {
      date,
      // eslint-disable-next-line no-useless-escape
      time: time.replace(/:[\d\.]+Z$/, ""),
      isoTimestamp,
      timeStamp: newTimeStamp,
      day,
    };
  } catch (err) {
    return {};
  }
};

export const timeMap = {
  "00:00": "12:00 AM",
  "00:30": "12:30 AM",
  "01:00": "01:00 AM",
  "01:30": "01:30 AM",
  "02:00": "02:00 AM",
  "02:30": "02:30 AM",
  "03:00": "03:00 AM",
  "03:30": "03:30 AM",
  "04:00": "04:00 AM",
  "04:30": "04:30 AM",
  "05:00": "05:00 AM",
  "05:30": "05:30 AM",
  "06:00": "06:00 AM",
  "06:30": "06:30 AM",
  "07:00": "07:00 AM",
  "07:30": "07:30 AM",
  "08:00": "08:00 AM",
  "08:30": "08:30 AM",
  "09:00": "09:00 AM",
  "09:30": "09:30 AM",
  "10:00": "10:00 AM",
  "10:30": "10:30 AM",
  "11:00": "11:00 AM",
  "11:30": "11:30 AM",
  "12:00": "12:00 PM",
  "12:30": "12:30 PM",
  "13:00": "01:00 PM",
  "13:30": "01:30 PM",
  "14:00": "02:00 PM",
  "14:30": "02:30 PM",
  "15:00": "03:00 PM",
  "15:30": "03:30 PM",
  "16:00": "04:00 PM",
  "16:30": "04:30 PM",
  "17:00": "05:00 PM",
  "17:30": "05:30 PM",
  "18:00": "06:00 PM",
  "18:30": "06:30 PM",
  "19:00": "07:00 PM",
  "19:30": "07:30 PM",
  "20:00": "08:00 PM",
  "20:30": "08:30 PM",
  "21:00": "09:00 PM",
  "21:30": "09:30 PM",
  "22:00": "10:00 PM",
  "22:30": "10:30 PM",
  "23:00": "11:00 PM",
  "23:30": "11:30 PM",
};

export function getDateForDayOfWeek(dayOfWeek: string): Date | null {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const inputDayIndex = days.indexOf(dayOfWeek);

  if (inputDayIndex === -1) {
    console.error("Invalid day of the week");
    return null;
  }

  const today = new Date();
  const currentDayIndex = today.getDay();
  const daysToAdd =
    inputDayIndex - currentDayIndex + (inputDayIndex < currentDayIndex ? 7 : 0);

  today.setDate(today.getDate() + daysToAdd);

  return today;
}

export function convertToAMPM(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  let period = "AM";
  let hours12 = hours;

  if (hours >= 12) {
    period = "PM";
    hours12 = hours === 12 ? 12 : hours - 12;
  }

  if (hours === 0) {
    hours12 = 12; // 0 hour is represented as 12 AM in 12-hour format
  }

  return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
}

export function getMonthYear(date) {
  const formattedDate = new Date(date).toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  });
  const result = formattedDate; // .replace(' ', '-')
  return result;
}

export function getYMDdateString(date) {
  const dateObj = new Date(date);
  const [day, month, year] = [
    dateObj.getDate(),
    dateObj.getMonth() + 1,
    dateObj.getFullYear(),
  ];
  return `${month}/${day}/${year}`;
}

export function extractIdsFromUrl(url: any) {
  const regex = /\/(?:d|folders)\/([a-zA-Z0-9-_]+)\/?.*(?:[?&]gid=([0-9]+))?/;
  const match = url.match(regex);

  if (match) {
    const ssid = match[1] || "";
    const gid = match[2] || "";

    return {
      ssid,
      gid,
      range: "",
    };
  }

  return null;
}
