import React, { Fragment, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MuiDrawer from "@mui/material/Drawer";
import styled from "@mui/material/styles/styled";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChart from "@mui/icons-material/BarChart";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import * as icons from "@mui/icons-material"; // Import all icons dynamically
import { Link, useParams } from "react-router-dom";
import AppContext from "../contexts/app";
import BrandLogo from "../components/BrandLogo";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Icon = ({ name, ...rest }) => {
  const IconComponent = icons[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    // border: 'none',
    height: "98vh",
    position: "relative",
    whiteSpace: "nowrap",
    width: `${process.env.REACT_APP_DRAWER_WIDTH}px`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function SlidingDrawer({ sidebar }) {
  const { secId, subSecId } = useParams();
  const { appContext, dispatch } = useContext(AppContext);

  const [open, setOpen] = React.useState("");

  function toggleMenu() {
    dispatch({ type: "TOGGLE_LEFT_SLIDER" });
  }

  return (
    <Drawer
      variant="permanent"
      open={appContext.leftSidebarOpen}
      sx={{
        "& .MuiDrawer-paperAnchorLeft": {
          height: "100vh",
          position: "sticky",
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [1],
        }}
      >
        <Box></Box>
        {appContext.leftSidebarOpen && <BrandLogo />}
        {appContext.leftSidebarOpen && (
          <IconButton onClick={toggleMenu}>
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider sx={{ mb: 1 }} />

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {appContext.leftSidebarOpen && (
          <Typography
            variant="button"
            display="block"
            textAlign="left"
            px={2}
            py={1}
          >
            DASHBOARDS
          </Typography>
        )}
        <ListItemButton
          onClick={() => dispatch({ type: "CHANGE_PAGE", page: "Dashboard" })}
          selected={appContext.page === "Dashboard"}
        >
          <ListItemIcon>
            <BarChart color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Vision Statement" />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />
        {appContext.leftSidebarOpen && (
          <Typography
            variant="button"
            display="block"
            textAlign="left"
            px={2}
            py={1}
          >
            Company Docs
          </Typography>
        )}
        {appContext?.DocsListData &&
          appContext?.DocsListData.map((list, index) => {
            return (
              <Fragment key={list.title + index}>
                <ListItemButton
                  onClick={() => setOpen(open === list.title ? "" : list.title)}
                >
                  <ListItemIcon>
                    <Icon name={list.icon || "Ballot"} />
                  </ListItemIcon>
                  <ListItemText primary={list.title} />
                  {open === list.title ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {list?.children.map((child) => (
                  <Collapse
                    in={open === list.title}
                    timeout="auto"
                    unmountOnExit
                    key={child.slug}
                  >
                    <List component="div" disablePadding>
                      <Link
                        to={child.slug}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <ListItemButton
                          sx={{ pl: 4 }}
                          selected={subSecId === child?.title}
                        >
                          <ListItemIcon>
                            <Icon name={child.icon} />
                          </ListItemIcon>
                          <ListItemText primary={child.title} />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                ))}
              </Fragment>
            );
          })}
      </List>
    </Drawer>
  );
}
