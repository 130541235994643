import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LinearProgress } from "@mui/material";
import DashboardWrapper from "../layouts/DashboardWrapper";
import AppContext from "../contexts/app";
import IframeRenderer from "../components/IframeRenderer";

export default function DocRenderer() {
  const { secId, subSecId } = useParams();
  const { appContext } = useContext(AppContext);

  const section = appContext.DocsListData?.find((sec) => sec.title === secId);
  const subSection = section?.children?.find(
    (subSec) => subSec.title === subSecId,
  );

  if (!subSection) return <LinearProgress />;

  return (
    <DashboardWrapper>
      <Box>
        <Typography variant="h5" color="primary" mb={4}>
          {subSection?.title}
        </Typography>
        <IframeRenderer url={subSection.url} />
      </Box>
    </DashboardWrapper>
  );
}
