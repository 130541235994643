import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Copyright() {
  return (
    <Typography my={1} variant="body2" color="text.secondary" align="center">
      {"© Copyright "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href={process.env.REACT_APP_URL}>
        {process.env.REACT_APP_NAME}
      </Link>{" "}
      {"- Inspired by imagination, Developed by "}
      <Link color="inherit" href={"https://www.ITfiers.com"}>
        ITfiers
      </Link>
    </Typography>
  );
}
