import React, { useContext, useEffect } from "react";
import AppContext from "../contexts/app";
import SignInLayout from "../layouts/SigninLayout";
import SignUpLayout from "../layouts/SignupLayout";
import FullScreenDialog from "./FullscreenDialog";
import Loader from "./Loader";
import { updateDBPost } from "../api";

export default function AuthWrapper({ children }: { children: JSX.Element }) {
  const { appContext, dispatch } = useContext(AppContext);

  const handleServerAuthResponse = (res: any, rememberUser?: boolean) => {
    const { status, message, data } = res;
    if (status === "Error") {
      dispatch({
        type: "OPEN_TOAST_ALERT",
        toastType: "error",
        message,
      });
      localStorage.removeItem(process.env.REACT_APP_TOKEN_TAG);
      localStorage.removeItem(process.env.REACT_APP_REMEMBER_ME_TAG);
    }
    if (status === "Success" && data?.token) {
      if (rememberUser)
        localStorage.setItem(process.env.REACT_APP_REMEMBER_ME_TAG, "true");
      localStorage.setItem(process.env.REACT_APP_TOKEN_TAG, data?.token);
      dispatch({
        type: "OPEN_TOAST_ALERT",
        toastType: "success",
        message,
      });
      dispatch({ type: "OPEN_LEFT_SLIDER" });
      dispatch({
        type: "UPDATE_USER",
        payload: {
          isAuthenticated: true,
          ...data,
        },
      });
      dispatch({ type: "CHANGE_PAGE", page: "Dashboard" });
    } else {
      dispatch({ type: "CHANGE_PAGE", page: "Signin" });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_TAG);
    const rememberUser = localStorage.getItem(
      process.env.REACT_APP_REMEMBER_ME_TAG,
    );
    if (!token || rememberUser !== "true") {
      dispatch({ type: "CHANGE_PAGE", page: "Signin" });
    } else {
      updateDBPost("auth/validateToken", null).then((res) => {
        handleServerAuthResponse(res);
      });
    }
    dispatch({ type: "STOP_LOADER" });
  }, []);

  if (!appContext.page) return <Loader height="100vh" />;

  return (
    <>
      {!appContext.user.isAuthenticated ? (
        <FullScreenDialog>
          {appContext.page === "Signin" ? (
            <SignInLayout />
          ) : appContext.page === "Signup" ? (
            <SignUpLayout />
          ) : (
            <p>404...Page Not Found...</p>
          )}
        </FullScreenDialog>
      ) : (
        children
      )}
    </>
  );
}
