import React from "react";

export default function BrandLogo() {
  return (
    <img
      alt={process.env.REACT_APP_NAME + " Logo"}
      width="auto"
      height="25px"
      style={{ marginRight: 8 }}
      src={process.env.REACT_APP_LOGO}
    />
  );
}
