import { App_Context, Context_Reducer_Actions } from "../Types/main";

export const defaultAppState: App_Context = {
  page: null,
  toastAlert: {
    status: false,
    message: "",
    type: null,
  },
  user: {
    isAuthenticated: false,
    username: "",
    email: "",
    isAdmin: false,
  },
  leftSidebarOpen: false,
  isLoading: true,
};

// eslint-disable-next-line import/prefer-default-export
export const appReducer = (
  state: App_Context,
  action: Context_Reducer_Actions,
) => {
  switch (action.type) {
    case "CHANGE_PAGE":
      return { ...state, page: action.page };
    case "UPDATE_STATE_PARENT_DATA":
      return { ...state, ...action.payload };
    case "UPDATE_USER":
      return { ...state, user: action.payload };
    case "TOGGLE_LEFT_SLIDER":
      return { ...state, leftSidebarOpen: !state.leftSidebarOpen };
    case "OPEN_LEFT_SLIDER":
      return { ...state, leftSidebarOpen: true };
    case "START_LOADER":
      return { ...state, isLoading: true };
    case "STOP_LOADER":
      return { ...state, isLoading: false };
    case "OPEN_TOAST_ALERT":
      return {
        ...state,
        toastAlert: {
          status: true,
          message: action.message,
          type: action.toastType,
        },
      };
    case "CLOSE_TOAST_ALERT":
      return {
        ...state,
        toastAlert: {
          status: false,
          message: "",
          type: null,
        },
      };
    case "INVALIDATE_PAGE_DATA":
      return { ...state, [action.page + "Data"]: null };
    case "CASES_POPUP_CLOSE":
      return { ...state, CasesPopup: null, isLoading: false };
    case "RESET_STATE":
      return defaultAppState;
    default:
      return state;
  }
};
