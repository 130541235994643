import * as React from "react";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import styled from "@mui/material/styles/styled";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BrandLogo from "./BrandLogo";
import AppContext from "../contexts/app";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: process.env.REACT_APP_DRAWER_WIDTH,
    width: `calc(100% - ${process.env.REACT_APP_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function MenuRenderer({
  menuId,
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  children,
}: {
  anchorEl: HTMLElement;
  handleMenuClose: () => void;
  isMenuOpen: boolean;
  menuId: string;
  children: React.ReactNode;
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {children}
    </Menu>
  );
}

export default function Header({ title }) {
  const { appContext, dispatch } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<{
    notification: null | HTMLElement;
    account: null | HTMLElement;
  }>({ notification: null, account: null });

  const handleSignout = () => {
    localStorage.setItem(process.env.REACT_APP_REMEMBER_ME_TAG, "");
    localStorage.setItem(process.env.REACT_APP_TOKEN_TAG, "");
    dispatch({
      type: "OPEN_TOAST_ALERT",
      toastType: "success",
      message: "Successfully Signed Out",
    });
    dispatch({ type: "RESET_STATE" });
    dispatch({ type: "CHANGE_PAGE", page: "Signin" });
  };

  function toggleMenu() {
    dispatch({ type: "TOGGLE_LEFT_SLIDER" });
  }

  return (
    <AppBar
      position="absolute"
      open={appContext.leftSidebarOpen}
      sx={{
        position: "fixed",
        bgcolor: "primary.contrastText",
      }}
    >
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={toggleMenu}
            sx={{
              marginRight: "36px",
              ...(appContext.leftSidebarOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!appContext.leftSidebarOpen && <BrandLogo />}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="notification of current user"
            aria-controls={
              anchorEl.notification ? "notification-menu" : undefined
            }
            aria-expanded={anchorEl.notification ? "true" : undefined}
            aria-haspopup="true"
            onClick={(event) =>
              setAnchorEl({ ...anchorEl, notification: event.currentTarget })
            }
            color="primary"
            sx={{ mx: 1 }}
          ></IconButton>
          <IconButton
            aria-label="account of current user"
            aria-controls={anchorEl.account ? "account-menu" : undefined}
            aria-expanded={anchorEl.account ? "true" : undefined}
            aria-haspopup="true"
            onClick={(event) =>
              setAnchorEl({ ...anchorEl, account: event.currentTarget })
            }
            color="primary"
          >
            <AccountCircle />
          </IconButton>
        </Box>
      </Toolbar>

      <MenuRenderer
        anchorEl={anchorEl.account}
        handleMenuClose={() => setAnchorEl({ ...anchorEl, account: null })}
        isMenuOpen={!!anchorEl.account}
        menuId={"account-menu"}
      >
        <MenuItem
          onClick={() => dispatch({ type: "CHANGE_PAGE", page: "Profile" })}
        >
          Your Profile
        </MenuItem>
        <MenuItem onClick={handleSignout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </MenuRenderer>
    </AppBar>
  );
}
