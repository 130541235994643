// ** MUI Imports
import React, { useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AppContext from "../contexts/app";
import DashboardWrapper from "../layouts/DashboardWrapper";
import IframeRenderer from "../components/IframeRenderer";

export default function Dashboard() {
  const { appContext, dispatch } = useContext(AppContext);

  return (
    <DashboardWrapper>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12} textAlign="center">
                  <Typography variant="h4" color="primary.main">
                    Mission and Values
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} textAlign="center">
                  <IframeRenderer url="https://docs.google.com/document/d/1iyvicoxZ7WYCJIpUjgSfdwASJAY7TsHXZpRERn8Jhaw/edit" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
}
