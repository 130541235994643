import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useContext } from "react";
import styled from "@mui/material/styles/styled";
import Header from "../components/Header";
import AppContext from "../contexts/app";
import SlidingDrawer, { DrawerHeader } from "./AppMenu";
import Copyright from "../components/Footer";

export default function DashboardWrapper({ children }) {
  const { appContext } = useContext(AppContext);

  const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme }) => ({
    flexGrow: 1,
    zIndex: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(appContext?.leftSidebarOpen && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header title={"CARES Portal"} />
        <SlidingDrawer sidebar={"left"} />
        <Main style={{ width: "70%" }}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
      <Copyright />
    </>
  );
}
