import React from "react";
// ** MUI Imports
import Paper from "@mui/material/Paper";
import { extractIdsFromUrl } from "../utils/misc";

const IframeRenderer = ({ url }: { url: string }) => {
  const sheetMeta = extractIdsFromUrl(url);

  return (
    <Paper elevation={3}>
      <iframe
        src={
          sheetMeta && url.includes("spreadsheets")
            ? `https://docs.google.com/spreadsheets/d/${sheetMeta?.ssid}/edit?rm=minimal#gid=${sheetMeta.gid}&range=${sheetMeta.range}&widget=false&headers=false&chrome=false&scrolling=no`
            : url.includes("/folders/")
              ? `https://drive.google.com/embeddedfolderview?id=${sheetMeta?.ssid}#list`
              : url.includes("drawings")
                ? `https://docs.google.com/drawings/d/${sheetMeta?.ssid}/view?rm=minimal`
                : url.includes("document")
                  ? `https://docs.google.com/document/d/${sheetMeta?.ssid}/view?rm=minimal`
                  : url
        }
        style={{
          display: "block",
          border: "none",
          height: "80vh",
          width: "100%",

          // margin: '-40px -15px -50px -66px'
        }}
        allowFullScreen
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      ></iframe>
    </Paper>
  );
};

export default IframeRenderer;
