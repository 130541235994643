import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useContext } from "react";
import AppContext from "../contexts/app";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

export default function Toast() {
  const { appContext, dispatch } = useContext(AppContext);

  const handleClose = () => {
    dispatch({ type: "CLOSE_TOAST_ALERT" });
  };

  return (
    <>
      {appContext.toastAlert.status && (
        <Snackbar
          open={appContext.toastAlert.status}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert severity={appContext.toastAlert.type} sx={{ width: "100%" }}>
            {appContext.toastAlert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
