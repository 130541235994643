import React, { useEffect, useReducer } from "react";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Toast from "../components/Toast";
import AuthWrapper from "../components/AuthWrapper";
import AppContext from "../contexts/app";
import DashboardWrapper from "../layouts/DashboardWrapper";
import { appReducer, defaultAppState } from "../stores/appReducer";
import Dashboard from "./Dashboard";
import { PageMap } from "../Types/main";
import { updateDBPost } from "../api";
import DocRenderer from "./DocRenderer";
import Error404 from "./Error404";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d2f57",
      contrastText: "#fff",
      light: "#5a6771",
    },
    secondary: {
      main: "#000",
      light: "#7dbb0014",
      contrastText: "#fff",
      dark: "#4C4E64",
    },
    // info: {
    //   main: "#7dbb0014",
    // },
  },
});

const PAGE_MAPPING: PageMap = {
  Dashboard: {
    component: <Dashboard />,
    endPoint: "Dashboard/getData",
  },
};

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Dashboard />,
//     id: "Dashboard/getData",
//     errorElement: <Error404 />,
//   },
//   {
//     path: "/section/:secId/sub-section/:subSecId",
//     element: <DocRenderer />,
//     errorElement: <Error404 />,
//   },
// ]);

export default function LandingPage() {
  const [appContext, dispatch] = useReducer(appReducer, defaultAppState);

  useEffect(() => {
    const page = appContext.page;
    const endPoint = PAGE_MAPPING[page as keyof typeof PAGE_MAPPING]?.endPoint;

    if (
      appContext?.[page + "Data"] ||
      !Object.keys(PAGE_MAPPING).includes(page) ||
      !endPoint
    )
      return;

    dispatch({ type: "START_LOADER" });
    updateDBPost(
      PAGE_MAPPING[page as keyof typeof PAGE_MAPPING].endPoint,
      null,
    ).then((res) => {
      if (res.code >= 200 && res.code < 300) {
        dispatch({
          type: "UPDATE_STATE_PARENT_DATA",
          payload: res.data,
        });
      } else {
        dispatch({
          type: "OPEN_TOAST_ALERT",
          toastType: "error",
          message: res.message,
        });
      }
      dispatch({ type: "STOP_LOADER" });
    });
  }, [appContext.page]);

  return (
    <AppContext.Provider value={{ appContext, dispatch }}>
      <ThemeProvider theme={theme}>
        <AuthWrapper>
          {/* {PAGE_MAPPING[appContext.page]?.component} */}
          <Router basename="/">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/section/:secId/sub-section/:subSecId"
                element={<DocRenderer />}
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Router>
        </AuthWrapper>
        <Toast />
      </ThemeProvider>
    </AppContext.Provider>
  );
}
