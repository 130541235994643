import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useContext } from "react";
import Paper from "@mui/material/Paper";
import Copyright from "../components/Footer";
import { updateDBPost } from "../api";
import AppContext from "../contexts/app";

export default function SignUpLayout() {
  const { dispatch } = useContext(AppContext);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email").toString();
    const password = data.get("password").toString();
    const username = data.get("username").toString();

    updateDBPost("auth/signup", { email, password, username }).then((res) => {
      if (res.status === "Error") {
        dispatch({
          type: "OPEN_TOAST_ALERT",
          toastType: "error",
          message: res.message,
        });
      } else {
        dispatch({
          type: "OPEN_TOAST_ALERT",
          toastType: "success",
          message: res.message,
        });
        dispatch({ type: "CHANGE_PAGE", page: "Signin" });
      }
    });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box></Box>
      <Paper
        elevation={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "#fff !important",
          p: 4,
        }}
      >
        <img
          alt={process.env.REACT_APP_NAME + " Logo"}
          width="auto"
          height="35px"
          src={process.env.REACT_APP_LOGO}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
          Welcome 👋🏻
        </Typography>
        <Typography variant="caption" color="secondary.dark">
          Please provide the following info to Sign Up
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="username"
                name="username"
                required
                fullWidth
                id="username"
                label="User Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid textAlign="center">
              <Typography variant="caption" color={"secondary.dark"}>
                Already have an account?
              </Typography>
              <Link
                sx={{ cursor: "pointer" }}
                variant="body2"
                onClick={() =>
                  dispatch({ type: "CHANGE_PAGE", page: "Signin" })
                }
              >
                Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
}
