import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useContext } from "react";
import AppContext from "../contexts/app";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ children }) {
  const { appContext } = useContext(AppContext);

  return (
    <div>
      <Dialog
        fullScreen
        open={!appContext.user.isAuthenticated}
        TransitionComponent={Transition}
      >
        {children}
      </Dialog>
    </div>
  );
}
